"use strict";
exports.__esModule = true;
exports.formatPrice = void 0;
var formatPrice = function (price) {
    var isWholeNumber = price % 1 === 0;
    return new Intl.NumberFormat('sk-SK', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: isWholeNumber ? 0 : 2
    }).format(price);
};
exports.formatPrice = formatPrice;
