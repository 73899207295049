"use strict";
exports.__esModule = true;
exports.isAvailableForItemPrice = exports.isAvailableForPrice = void 0;
var number_1 = require("../../utils/number");
var isAvailableForPrice = function (paymentMethod, price) {
    var promotion = paymentMethod.promotions[0];
    return (0, number_1.isInRange)(price, promotion.minGoodsPrice, promotion.maxGoodsPrice);
};
exports.isAvailableForPrice = isAvailableForPrice;
var isAvailableForItemPrice = function (paymentMethod, price) {
    var promotion = paymentMethod.promotions[0];
    return (0, number_1.isInRange)(price, promotion.minGoodsItemPrice, promotion.maxGoodsPrice);
};
exports.isAvailableForItemPrice = isAvailableForItemPrice;
