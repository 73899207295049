"use strict";
exports.__esModule = true;
exports.push = exports.injectScript = void 0;
var injectScript = function (gtagID) {
    // inject script tag into head
    var script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = "https://www.googletagmanager.com/gtag/js?id=".concat(gtagID);
    document.head.appendChild(script);
    // create data layer if not exists
    window.dataLayer = window.dataLayer || [];
    // handle promise
    return new Promise(function (resolve, reject) {
        script.addEventListener('load', function () {
            push('js', new Date());
            push("config", gtagID, { send_page_view: false });
            resolve();
        });
        script.onerror = function () { return reject(); };
    });
};
exports.injectScript = injectScript;
function push() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    window.dataLayer.push(arguments);
}
exports.push = push;
